import * as yup from 'yup';

// Password validation: for error messages
export const validatePassword = yup
    .string()
    .required('Password is required')
    .min(10, 'Password should be 10 characters minimum')
    .matches(/([a-z])/, 'Your password does not match the criteria.')
    .matches(/([A-Z])/, 'Your password does not match the criteria.')
    .matches(/([0-9])/, ' Your password does not match the criteria.')
    .matches(/([^a-zA-Z\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z\d])+/, 'Your password does not match the criteria.');

// New Password: for error messages
export const validateNewPassword = yup
    .string()
    .required('New password is required')
    .min(10, 'New password should be 10 characters minimum')
    .matches(/([a-z])/, 'Your new password does not match the criteria.')
    .matches(/([A-Z])/, 'Your new password does not match the criteria.')
    .matches(/([0-9])/, ' Your new password does not match the criteria.')
    .matches(/([^a-zA-Z\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z\d])+/, 'Your new password does not match the criteria.');

// Detailed information for password fields
export const validatePasswordDetailed = yup
    .string()
    .required('Password is required')
    .min(10, 'Password should be 10 characters minimum')
    .matches(/([a-z])/, 'Your password should contain at least one lowercase letter.')
    .matches(/([A-Z])/, 'Your password should contain at least one uppercase letter.')
    .matches(/([0-9])/, 'Your password should contain at least one number.')
    .matches(/([^a-zA-Z\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z\d])+/, 'Your password should contain at least one special character: !@#$%^&*');

// Draw Games: for Error Messages
export const validateDrawEntry = yup
    .string()
    .required('Please completely fill out all of the entry fields.')
    .min(25, 'Please completely fill out all of the entry fields.');

// Draw Games: for Error Messages
export const validateScratcherEntry = yup
    .string()
    .required('Please completely fill out all of the entry fields.')
    .min(22, 'Please completely fill out all of the entry fields.')
    .matches(/^\d+$/, 'Scratcher game fields should be numbers only.');
