import React from 'react';
import { connect } from 'react-redux';
import { getCMSObject, CurrentSweepstakes } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const Promotions = ({ user, config, sweep, cmsSourceFirebase, actions, match }) => {
    const telescriptCurrentSweepstakes = getCMSObject('data.components.teleScripts.currentSweepstakes.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <section id='promotions'>
                <CurrentSweepstakes
                    {...{ sweep, cmsSourceFirebase, actions }}
                    telescript={telescriptCurrentSweepstakes}
                    options={{ hasCollapser: false, variant: 'theme-primary' }}
                />
            </section>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
