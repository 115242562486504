import promotion from '../../../promotionConfig';
import { isBrowser } from 'sg-ui-components';

const LoginClick = (config) => {
    const _config = config;
    return () => {
        let myLocation = window.location.protocol + '//' + window.location.host + '/login-pta';
        //? keeping this for later: window.location.pathname;

        // remove any trailing slashes
        myLocation = myLocation.replace(/\/$/, '');

        const defaultLoginUrl = promotion.defaultLoginLink;

        const loginUrl = _config.config?.login_host_uri ? `${_config.config.login_host_uri}?destURL=${myLocation}` : defaultLoginUrl;

        window.location.href = loginUrl;
    };
};

const RegisterClick = (config) => {
    const _config = config;
    return () => {
        let myLocation = isBrowser() ? window.location.protocol + '//' + window.location.host + window.location.pathname : '';

        // remove any trailing slashes
        myLocation = myLocation.replace(/\/$/, '');

        const defaultRegisterUrl = promotion.defaultRegisterLink;

        const registerUrl = _config.config.registration_host_uri
            ? `${_config.config.registration_host_uri}?PtaUrl=${myLocation}&Redirect=${myLocation}&rurl=${myLocation}`
            : defaultRegisterUrl;
        window.location.href = registerUrl;
    };
};

const LogoutClick = (config, actions) => {
    const _config = config;
    const _actions = actions;
    return async () => {
        const myHost = window.location.protocol + '//' + window.location.host;
        const logoutDestination = promotion.getLogoutDestination(myHost, _config.config.logout_host_uri);

        await _actions.userActions.logout();
        window.location.search = '';
        window.location.href = logoutDestination;
    };
};

export { LoginClick, RegisterClick, LogoutClick };
