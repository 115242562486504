/**
 * A simple wrapper component what could help us to render based on conditions.
 */
var DisplayContent = function (_a) {
    var isVisible = _a.isVisible, children = _a.children;
    return isVisible ? children : '';
};
DisplayContent.defaultProps = {
    children: null,
};
export default DisplayContent;
