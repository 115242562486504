import React, { useLayoutEffect } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { IfNotMobile, CMSContent, TitleBreadcrumb, StyleOverrides, FeatureLockout, ErrorBoundary, TeleScript, getCMSObject, getInterpolatedPageData } from 'sg-ui-components';

import { Header } from './Layouts/Header';
import WrapperHeader from './Layouts/Wrapper/WrapperHeader';
import WrapperFooter from './Layouts/Wrapper/WrapperFooter';
import IdleTimeout from './IdleTimeout';
import { camelCase } from 'lodash';

import { testing, routes } from '../promotionConfig';
import { scrollToHashElement } from '../utils/scrollToHashElement';

const Layout = ({ user, config, cmsSourceFirebase, actions, children, match, configReplacements }) => {
    const path = match?.path;
    const pageData = getInterpolatedPageData(routes, match, configReplacements);

    const errorBoundaryFallback = getCMSObject('data.messages.pageErrorMessages.jsonBlock')?.[`${camelCase(path) || 'home'}Fallback`];

    // Do not render title breadcrumbs on user locked out page before auth check
    const canDisplayTitleBreadcrumb = () => {
        if (user?.loggedIn) { 
            return true;
        }

        if (pageData?.userLockout) {
            return false;
        }

        return !!pageData;
    };

    //? Note: Frontend config will be requested and stored on first pageload / refresh
    useLayoutEffect(() => {
        async function configStateActions() {
            await actions.configActions.getConfig();
        }

        if (!config?.config?.firebase) {
            configStateActions();
            //? For debug: console.log('🟢 configStateActions()');
        }
    }, []);

    //? Note: Firebase CMS webContent and webMenu will be requested and stored on first pageload / refresh
    useLayoutEffect(() => {
        async function pageStateActions() {
            await actions.cmsSourceFirebaseActions.storeAllData({
                config: testing?.firebase ?? config.config.firebase,
                docName: 'webContent',
            });

            await actions.cmsSourceFirebaseActions.storeAllData({
                config: testing?.firebase ?? config.config.firebase,
                docName: 'webMenu',
            });

            await actions.cmsSourceFirebaseActions.setLoaded(true);
            scrollToHashElement();
        }

        if (config?.config?.firebase && !cmsSourceFirebase?.loaded) {
            pageStateActions();
            //? For debug: console.log('🟢 pageStateActions()');
        }
    }, [config]);

    useLayoutEffect(() => {
        async function playerStateActions() {
            await actions.userActions.getPlayerData();

            if (user.loggedIn) {
                await actions.revealTokenActions.getRevealToken();
            }
            scrollToHashElement();
        }
        playerStateActions();
        //? For debug: console.log('🟢 playerStateActions()');
    }, [user?.loggedIn]);

    // Get GTM ID based on the environment from the config api
    if (config?.config?.gtm_id) {
        ReactGA.initialize(config?.config?.gtm_id);
    }

    return (
        <FeatureLockout config={config} page={pageData} user={user} options={{ redirectPath: pageData?.featureDisabledRedirectPath }}>
            <Helmet defaultTitle='Georgia Lottery' titleTemplate='%s | Georgia Lottery'>
                <title>{pageData?.title}</title>
            </Helmet>
            <IfNotMobile>
                <WrapperHeader />
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='fixed-top' noticePlacement='web-notice-top-fixed' />
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='' noticePlacement='web-notice-top' />
            </IfNotMobile>
            <div className={`content-root ${pageData?.className}`}>
                <Header />
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='' noticePlacement='web-notice-below-header' />
                {!cmsSourceFirebase?.loaded ? (
                    <div className='loading-wrapper'>
                        <div className='loading-overlay'>
                            <div className='loading-icon ellipses'></div>
                        </div>
                    </div>
                ) : (
                    <ErrorBoundary fallback={<TeleScript line={errorBoundaryFallback} />} details={{ pageData, user, config }}>
                        <div className={`${pageData?.className}-container`}>
                            <div className='galaxy-fold-open-your-device d-none'></div>
                            <div className='layout container'>
                                <div className='main row'>
                                    <div className='page-content w-100'>
                                        {canDisplayTitleBreadcrumb() && <TitleBreadcrumb pageData={pageData} />}
                                        {children}
                                    </div>
                                </div>
                            </div>
                            <IdleTimeout />
                        </div>
                    </ErrorBoundary>
                )}
            </div>
            <IfNotMobile>
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='fixed-bottom' noticePlacement='web-notice-bottom-fixed' />
                <WrapperFooter />
            </IfNotMobile>
            <StyleOverrides cmsSourceFirebase={cmsSourceFirebase} />
        </FeatureLockout>
    );
};

export default Layout;
