import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ScrollToTop, ErrorBoundary, getCMSObject, TeleScript } from 'sg-ui-components';

import { createStore } from './Store';

import HomePage from './pages/home';
import Feedback from './pages/feedback';
import Promotions from './pages/promotions';
import SecondChancePromotion from './pages/secondChancePromotion';
import MyActivityPage from './pages/myActivity';

import PostClaimPage from './pages/postClaim';
import WinnersPage from './pages/winners';
import PageNotFound from './pages/404';

import Login from './pages/login';
import LoginPTA from './pages/loginPta';
import Logout from './pages/logout';

import EnterPage from './pages/enter';

import './styling/global.scss';

const app = document.getElementById('root');

const errorBoundaryTelescript = getCMSObject('data.messages.pageErrorMessages.jsonBlock');

render(
    <Provider store={createStore()}>
        <ErrorBoundary fallback={<TeleScript line={errorBoundaryTelescript?.rootFallback} />} details={{}}>
            <Router basename='/'>
                <ScrollToTop />
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route exact path='/' component={HomePage} />
                        <Route path='/home' component={HomePage} />
                        <Route path='/winners' component={WinnersPage} />
                        <Route path='/promotions' component={Promotions} />
                        <Route path='/second-chance-promotion' component={SecondChancePromotion} />
                        <Route path='/feedback' component={Feedback} />
                        <Route path='/post-claim' component={PostClaimPage} />
                        <Route path='/my-activity' component={MyActivityPage} />
                        <Route path='/login' component={Login} />
                        <Route path='/login-pta' component={LoginPTA} />
                        <Route path='/logout' component={Logout} />
                        <Route path='/enter' component={EnterPage} />
                        <Route path='*' component={PageNotFound} />
                    </Switch>
                </Suspense>
            </Router>
        </ErrorBoundary>
    </Provider>,
    app
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
