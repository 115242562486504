import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { MyDrawingEntries, MyTicketHistory, getCMSObject, MyAllocatableEntries, TeleScript, ErrorBoundary } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import WinnerLinkWrapper from '../Components/WinnerLinkWrapper';
import UserLockout from '../utils/user_lockout';
import siteConfig from '../promotionConfig';
import AllocatableEntriesForm from '../Components/AllocatableEntries/AllocatableEntriesForm';

const MyActivityPage = ({ user, winner, reward, activity, config, cmsSourceFirebase, actions, match, sweep, loading }) => {
    const myDrawingEntriesTelescript = getCMSObject('data.components.teleScripts.myDrawingEntries.jsonBlock');
    const myTicketHistoryTelescript = getCMSObject('data.components.teleScripts.myTicketHistory.jsonBlock');

    const myAllocatableTelescript = getCMSObject('data.components.teleScripts.myAllocatableEntries.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, activity, match }}>
            <UserLockout>
                <Container>
                    <ErrorBoundary fallback={<TeleScript line={myAllocatableTelescript?.fallback} />} details={{ user, config, reward }}>
                        <MyAllocatableEntries
                            options={{
                                hasCollapser: true,
                            }}
                            actions={actions}
                            sweep={sweep}
                            loading={loading}
                            AllocatableForm={(entry) => (
                                <AllocatableEntriesForm
                                    sweepId={entry.sweeps_id ?? ''}
                                    promotionName={entry.sweepstake_name ?? ''}
                                    numEntries={entry.quantity ?? ''}
                                    entryId={entry.id ?? ''}
                                />
                            )}
                            telescript={myAllocatableTelescript}
                        />
                    </ErrorBoundary>
                    <ErrorBoundary fallback={<TeleScript line={myDrawingEntriesTelescript?.fallback} />} details={{ user, config, reward }}>
                        <MyDrawingEntries
                            winner={winner}
                            actions={actions}
                            telescript={myDrawingEntriesTelescript}
                            WinnerLink={WinnerLinkWrapper}
                            options={{
                                variant: 'theme-primary',
                                hasCollapser: true,
                                showByDefault: true,
                                hash: 'drawing-entries',
                                pageSize: siteConfig?.components?.drawingEntries?.defaultPageSize ?? 5,
                                promotionDetailsLink: '/second-chance-promotion',
                            }}
                        />
                    </ErrorBoundary>
                    <ErrorBoundary fallback={<TeleScript line={myTicketHistoryTelescript?.fallback} />} details={{ user, config, reward }}>
                        <MyTicketHistory
                            actions={actions}
                            activity={activity}
                            telescript={myTicketHistoryTelescript}
                            options={{
                                variant: 'theme-primary',
                                hasCollapser: true,
                                showByDefault: true,
                                hash: 'ticket-history',
                                pageSize: siteConfig?.components?.ticketHistory?.defaultPageSize ?? 5,
                                promotionsLink: '/promotions',
                            }}
                            resolveCurrency={siteConfig.resolveCurrency}
                        />
                    </ErrorBoundary>
                </Container>
            </UserLockout>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyActivityPage);
