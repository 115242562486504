import { jsx as _jsx } from "react/jsx-runtime";
import { Redirect } from 'react-router-dom';
import { logError } from '../../utils/logError';
/**
 * Gatekeeper component to handle redirection policy for restricted site features
 */
var FeatureLockout = function (_a) {
    var _b, _c;
    var config = _a.config, page = _a.page, options = _a.options, children = _a.children, user = _a.user;
    // configs need to be loaded for further investigation
    if (!(config === null || config === void 0 ? void 0 : config.loaded)) {
        return null;
    }
    // local page object should have a feature prop. if not, the site is not guarded
    if (!(page === null || page === void 0 ? void 0 : page.feature)) {
        return children;
    }
    // only show the site feature when the local page feature is found in web config as true
    if (((_c = (_b = config === null || config === void 0 ? void 0 : config.web) === null || _b === void 0 ? void 0 : _b.features) === null || _c === void 0 ? void 0 : _c[page === null || page === void 0 ? void 0 : page.feature]) === true) {
        return children;
    }
    var redirectPath = (options === null || options === void 0 ? void 0 : options.redirectPath) || '/home';
    logError({
        errorCode: 'PAGE_NOT_FOUND',
        severity: 'critical',
        category: 'generic',
        user: user,
        details: { message: "User requested a feature flagged route for ".concat(page === null || page === void 0 ? void 0 : page.feature, " that is not enabled. User will be redirected to ").concat(redirectPath, ".") },
    });
    return _jsx(Redirect, { to: redirectPath });
};
export default FeatureLockout;
