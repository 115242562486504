/*
Note: `IGTOnlyScanner` component is build for phase one of GA Ensemble. Probably this will be never part of the product.
It's needed, because in the phase one of the GA Ensemble there is no manual ticket entry, only scan.
*/

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ErrorMessage } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../../Store';
import ScannerIGT from './ScannerIGT';

const IGTOnlyScanner = ({ igt24Ticket, actions, resetParentState }) => {
    const [ticketError, setTicketError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [ticketSuccess, setTicketSuccess] = useState(false);
    //? For testing: const testTicket = useRef('');

    const initialEntryFormValues = {
        ticket1_1_1: '',
        ticket1_1_2: '',
        ticket1_1_3: '',
        entry_method: 'SCAN',
    };

    const triggerSubmitTicket = async (ticketVal) => {
        //* Clear manually entered ticket error messages from the parent
        resetParentState();

        //? for debug: console.log('🚀 triggerSubmitTicket', ticketVal);
        setDisabled(true);
        const payload = { ...initialEntryFormValues, ticket1_1_1: ticketVal };
        await actions.igt24TicketActions.enterIGT24Ticket(payload);
    };

    const triggerWasScanned = (wasScanned) => {
        if (wasScanned) {
            //? for debug: console.log('🎉 triggerWasScanned');
        }
    };

    const triggerScannerError = async (errorMessage) => {
        //* Clear manually entered ticket error messages from the parent
        resetParentState();

        //? for debug: console.log('🚀 triggerScannerError', errorMessage);
        setTicketError(errorMessage);
        const payload = {
            gameOver: false,
            success: false,
            message: errorMessage,
            details: errorMessage,
        };
        await actions.igt24TicketActions.override(payload);
    };

    useEffect(() => {
        async function clearData() {
            await actions.igt24TicketActions.reset();
        }

        clearData();
    }, []);

    useEffect(() => {
        if (disabled) {
            if (igt24Ticket.success) {
                setTicketError('');
                setTicketSuccess(true);
            } else {
                setTicketError(igt24Ticket.message);
            }
            setDisabled(false);
        }
        if (igt24Ticket.message === '_PLAYER_LOCKOUT') {
            setDisabled(true);
        }
    }, [igt24Ticket]);

    useEffect(() => {
        setTicketError(igt24Ticket.message);
    }, [igt24Ticket.message]);

    return (
        <section>
            {ticketSuccess ? <Redirect to='/post-claim' /> : null}

            <ErrorMessage code={ticketError} collection='data.messages.ticketErrorMessages.jsonBlock' />

            <ScannerIGT
                className='btn theme-btn theme-secondary mx-auto'
                // pass functions for callback that would set the form entry field and the scanned boolean
                updateScannerError={triggerScannerError}
                updateEntryForm={triggerSubmitTicket}
                updateScanStatus={triggerWasScanned}
            />

            {/*
            //? For testing:
            <section className='testing-section'>
                <button onClick={() => triggerScannerError('Scanner: sample error')}>
                    TEST: Scanner error
                </button>

                <button onClick={() => resetParentState()}>
                    TEST: resetParentState
                </button>
            </section>
            */}

            {/*
            //? For testing:
            <section className='testing-section'>
                <strong>Test scan ticket number:</strong>

                <input
                    type="text"
                    className="form-control theme-input"
                    ref={testTicket}
                    placeholder="Scan ticket number"
                />

                <button
                    className='btn theme-btn theme-secondary mt-4'
                    onClick={ () => triggerSubmitTicket(testTicket.current.value)}
                >
                    SUBMIT SCAN TICKET
                </button>
            </section>
            */}
        </section>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(IGTOnlyScanner);
