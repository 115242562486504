import React from 'react';
import { connect } from 'react-redux';
import { getCMSObject, NextDrawings, formatDateTBD, RecentDrawings, ErrorBoundary, TeleScript } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import WinnerLinkWrapper from '../Components/WinnerLinkWrapper';

import promotionConfig from '../promotionConfig';

const WinnersPage = ({ user, config, winner, cmsSourceFirebase, actions, match }) => {
    const telescriptNextDrawings = getCMSObject('data.components.teleScripts.nextDrawings.jsonBlock');
    const telescriptRecentDrawings = getCMSObject('data.components.teleScripts.RecentDrawings.jsonBlock');
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <ErrorBoundary fallback={<TeleScript line={telescriptNextDrawings?.fallback} />} details={{ user, config }}>
                <NextDrawings
                    {...{ user, winner, cmsSourceFirebase, actions }}
                    formatDate={formatDateTBD?.dateWithDayOfTheWeek}
                    telescript={telescriptNextDrawings ?? ''}
                    options={{
                        variant: 'theme-primary',
                        limitOnePerSweepstake: promotionConfig?.components?.nextDrawings?.limitOnePerSweepstake ?? true,
                        maxElement: promotionConfig?.components?.nextDrawings?.maxElement ?? 5,
                    }}
                />
            </ErrorBoundary>
            <ErrorBoundary fallback={<TeleScript line={telescriptRecentDrawings?.fallback} />} details={{ user, config }}>
                <RecentDrawings
                    winner={winner}
                    actions={actions}
                    telescript={telescriptRecentDrawings}
                    WinnerLink={WinnerLinkWrapper}
                    options={{
                        variant: 'theme-primary',
                        hasCollapser: true,
                        hash: 'recent-drawings',
                        pageSize: promotionConfig?.components?.recentDrawings?.defaultPageSize ?? 10,
                        promotionDetailsLink: '/second-chance-promotion',
                    }}
                />
            </ErrorBoundary>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WinnersPage);
