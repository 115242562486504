import React from 'react';
import { connect } from 'react-redux';
import { CMSContent } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

const TicketEntryBlurb = ({ cmsSourceFirebase }) => {
    return (
        <div className='ticket-entry-blurb'>
            <CMSContent localStorageObject='webContent' contentPath='data.ticketEntry.ticketEntryIntro.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketEntryBlurb);
