import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import { Redirect } from 'react-router-dom';
import { isApp } from 'sg-ui-components';

const Login = ({ user, config, cmsSourceFirebase, actions, match }) => {
    useEffect(() => {
        if (config.config && !user.loggedIn) {
            handleLogin();
        }
    }, [config, user]);

    const handleLogin = async () => {
        if (isApp()) {
            let myLocation = typeof window !== 'undefined' ? window.location.protocol + '//' + window.location.host + '/home' : '';

            // remove any trailing slashes
            myLocation = myLocation.replace(/\/$/, '');

            //? for debug: console.log('🚀 triggerAppLogin from: /login');
            window.parent.postMessage(
                {
                    message: 'openLogin',
                    redirect: myLocation,
                },
                '*'
            );
            window.location.href = myLocation;
        } else {
            let myLocation = window.location.protocol + '//' + window.location.host + '/login-pta';

            // remove any trailing slashes
            myLocation = myLocation.replace(/\/$/, '');

            if (config.config?.login_host_uri) {
                const loginUrl = `${config.config.login_host_uri}?destURL=${myLocation}`;
                window.location.href = loginUrl;
            }
        }
    };

    return <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>{user.loggedIn ? <Redirect to='/enter' /> : null}</Layout>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
