var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TableDesktop from '../TableDesktop/TableDesktop';
import TableMobile from '../TableMobile/TableMobile';
import './pfd-style.scss';
var WinnersTable = function (_a) {
    var _b, _c, _d;
    var winners = _a.winners, telescript = _a.telescript;
    var winnersDisplayHeaders = {
        winner: (_b = telescript === null || telescript === void 0 ? void 0 : telescript.tableWinner) !== null && _b !== void 0 ? _b : 'Winner',
        cityState: (_c = telescript === null || telescript === void 0 ? void 0 : telescript.tableCityState) !== null && _c !== void 0 ? _c : 'City, State',
    };
    var winnersDisplayOptions = {
        tableId: 'myDrawingEntriesAndWinnersWinnersTable',
        isLoaded: true,
        messageNoRows: (_d = telescript === null || telescript === void 0 ? void 0 : telescript.noDrawings) !== null && _d !== void 0 ? _d : 'No winners',
    };
    var winnersDisplayRows = (winners === null || winners === void 0 ? void 0 : winners.map(function (winner) { return (__assign(__assign({}, winner), { fields: {
            winner: "".concat(winner === null || winner === void 0 ? void 0 : winner.firstname, " ").concat(winner === null || winner === void 0 ? void 0 : winner.lastname),
            cityState: "".concat(winner === null || winner === void 0 ? void 0 : winner.city, ", ").concat(winner === null || winner === void 0 ? void 0 : winner.state),
        } })); })) || [];
    return (_jsx("div", { className: 'd-flex justify-content-center mt-3', children: _jsxs("div", { className: 'pfd-winners-table', children: [_jsx(TableDesktop, { headers: winnersDisplayHeaders, rows: winnersDisplayRows, options: winnersDisplayOptions }), _jsx(TableMobile, { headers: winnersDisplayHeaders, rows: winnersDisplayRows, options: winnersDisplayOptions })] }) }));
};
export default WinnersTable;
