/**
    This should be exported from the UI lib because its more useful than i thought it would be
    maybe not just for styles though
    original name mergeStyles
*/
import object from 'lodash/object';

export const mergeCopy = (defaultCopy, newCopy) => {
    return object.merge({}, defaultCopy, newCopy);
};
