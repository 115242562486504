import React from 'react';
import { FindScratchNumbers, FindDrawNumbers, DisplayContent } from 'sg-ui-components';
import promotionConfig from '../../promotionConfig';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

const TicketSamples = ({ cmsSourceFirebase }) => {
    return (
        <div className='row justify-content-center'>
            <div className='col-12'>
                <DisplayContent isVisible={promotionConfig.scratchTicketFields}>
                    <FindScratchNumbers.FindScratchNumbers cmsSourceFirebase={cmsSourceFirebase} />
                </DisplayContent>

                <DisplayContent isVisible={promotionConfig.drawFields}>
                    <FindDrawNumbers.FindDrawNumbers cmsSourceFirebase={cmsSourceFirebase} />
                </DisplayContent>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketSamples);
